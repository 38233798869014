<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose" :title="formTitle">
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="卡号" prop="cardNo">
        <a-select
          show-search
          placeholder="请输入加油卡卡号模糊查找"
          :default-active-first-option="false"
          :show-arrow="false"
          :filter-option="false"
          :not-found-content="null"
          @search="handleCardNoSearch"
          @change="handleCardNoChange"
          :loading="loading"
          :disabled="formType == 2 || isOnlyView"
          v-model="form.cardNo"
        >
          <a-select-option v-for="d in cardNoArray" :key="d.cardNo">
            {{ d.cardNo }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="卡类型" prop="cardNo">
        {{ cardTypeName }}
      </a-form-model-item>
      <a-form-model-item label="已绑定车辆" prop="cardNo" v-if="form.isMain == '1'">
        {{ form.isMain == '1' ? form.licensePlateNum + '|' + form.vehicleNo : '' }}
      </a-form-model-item>
      <a-form-model-item label="此卡当前总余额（元）" prop="balanceMainCard">
        {{ form.isMain == '0' ? form.balanceMainCard : form.balanceBranchCard }}
      </a-form-model-item>
      <a-form-model-item label="此卡当前未划拨余额（元）" prop="balanceBranchCard" v-if="form.isMain == '0'">
        {{ form.unTransferBalance }}
      </a-form-model-item>
      <a-form-model-item label="核销发起人" prop="createBy">
        {{ form.createBy }}
      </a-form-model-item>
      <a-form-model-item label="发起时间" prop="createTime">
        {{ form.createTime }}
      </a-form-model-item>
      <a-form-model-item label="核销类型" prop="hx">
        <a-radio-group v-model="form.cancelVerificationType" button-style="solid" v-if="!isOnlyView">
          <a-radio-button v-for="(d, index) in hxOptions" :key="index" :value="d.value">{{ d.label }}</a-radio-button>
        </a-radio-group>
        <span v-else>{{ form.cancelVerificationType == 1 ? '核减' : '核增' }}</span>
      </a-form-model-item>
      <a-form-model-item label="核销金额" prop="amount">
        <a-input v-model="form.amount" placeholder="请输入核销金额" v-input-filter:number :disabled="isOnlyView" />
      </a-form-model-item>
      <a-form-model-item label="说明" prop="comment">
        <a-textarea v-model="form.comment" placeholder="请输入说明" :disabled="isOnlyView" />
      </a-form-model-item>
      <div class="bottom-control" v-if="!isOnlyView">
        <a-space>
          <a-button type="primary" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getOilAccountRecord, updateOilAccountRecord, cancelVerification } from '@/api/iot/oilAccountRecord'
import { listOilCard } from '@/api/iot/oilCard'
import debounce from 'lodash/debounce'
import { mapState } from 'vuex'
import { parseDateTime } from '@/utils/ruoyi'
export default {
  name: 'CreateForm',
  props: {},
  components: {},
  data() {
    this.handleCardNoSearch = debounce(this.handleCardNoSearch, 500)
    return {
      loading: false,
      formTitle: '',
      isOnlyView: false,
      // 表单参数
      form: {
        cardNo: null,
        accountType: 'cancel_verification',
        amount: null,
        amountTransfer: null,
        balanceMainCard: null,
        balanceBranchCard: null,
        inchargeBy: null,
        comment: null,
        approvalStatus: null,
        createTime: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        cardNo: [{ required: true, message: '加油卡卡号不能为空', trigger: 'blur' }],
        accountType: [{ required: true, message: '账务类型', trigger: 'change' }],
        // balanceMainCard: [{ required: true, message: '当前主卡总余额不能为空', trigger: 'blur' }],
        // balanceBranchCard: [{ required: true, message: '当前分卡总余额不能为空', trigger: 'blur' }],
        inchargeBy: [{ required: true, message: '该笔账务责任人不能为空', trigger: 'change' }]
      },
      hxOptions: [
        { label: '核减', value: 1 },
        { label: '核增', value: 2 }
      ],
      // 卡号列表
      cardNoArray: [],
      cardTypeName: ''
    }
  },
  filters: {},
  created() {},
  computed: {
    ...mapState({
      userInfo: state => state.user.info
    })
  },
  watch: {},
  mounted() {},
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        cardNo: null,
        accountType: 'cancel_verification',
        amount: null,
        amountTransfer: null,
        balanceMainCard: null,
        balanceBranchCard: null,
        inchargeBy: null,
        comment: null,
        approvalStatus: null,
        createTime: null,
        // 核销类型
        cancelVerificationType: 1,
        // 是否主卡
        isMain: ''
      }
    },
    /** 新增按钮操作 */
    handleAdd(title, record) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = title
      this.initForm(record)
    },
    initForm(record) {
      this.isOnlyView = false
      if (record) {
        this.isOnlyView = true
        Object.assign(this.form, record)
        // 当mainCardNo为0时代表是主卡，否则为分卡
        if (this.form.mainCardNo === '0') {
          this.cardTypeName = '主卡'
          this.form.isMain = '0'
        } else {
          this.cardTypeName = '分卡'
          this.form.isMain = '1'
        }
      } else {
        this.form.createBy = this.userInfo.userName
        this.form.inchargeBy = this.userInfo.userId
        this.form.createTime = parseDateTime(new Date(), 'yyyy-MM-dd HH:mm:ss')
      }
      console.log('this.form', this.form)
      this.$forceUpdate()
    },
    formatForm() {
      console.log('hx', this.hx)
      // if (this.hx === 0) {
      //   this.form.amount = -parseFloat(this.form.amount)
      // }
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getOilAccountRecord(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.id !== undefined && this.form.id !== null) {
            updateOilAccountRecord(this.form).then(response => {
              this.$message.success('修改成功', 3)
              this.open = false
              this.$emit('ok')
            })
          } else {
            this.formatForm()
            cancelVerification(this.form).then(response => {
              this.$message.success('新增成功', 3)
              this.open = false
              this.$emit('ok')
            })
          }
        } else {
          return false
        }
      })
    },
    handleCardNoSearch(value) {
      console.log('handleCardNoSearch', value)
      const queryParam = {
        cardNo: value
      }
      if (!value && value !== 0) {
        queryParam.pageNum = 1
        queryParam.pageSize = 10
      }
      queryParam.status = 1
      this.loading = true
      listOilCard(queryParam).then(response => {
        this.cardNoArray = response.rows
        this.loading = false
      })
    },
    handleCardNoChange(value) {
      // 所属主卡卡号
      const tmpArr = this.cardNoArray.filter(p => p.cardNo === value)
      if (tmpArr.length === 1) {
        this.form.mainCardNo = tmpArr[0].mainCardId
        this.form.isMain = tmpArr[0].isMain
        if (this.form.isMain === '0') {
          // 主卡总余额
          this.form.balanceMainCard = tmpArr[0].balanceTotal
          // 未划拨余额
          this.form.unTransferBalance = tmpArr[0].balanceAvailable
          this.cardTypeName = '主卡'
        } else {
          // 当前分卡总余额
          this.form.balanceBranchCard = tmpArr[0].balanceAvailable
          this.cardTypeName = '分卡'
        }

        if (this.form.isMain === '1') {
          this.form.vehicleNo = tmpArr[0].vehicleNo
          this.form.licensePlateNum = tmpArr[0].licensePlateNum
        }
      }
    }
  }
}
</script>
